import BgHolder from "../components/BgHolder";
import Sponsors from "../components/Sponsors";
import TwoSidedView from "../components/TwoSidedView";
import headerImage from "../assets/header/entencup-2016-03-edit.jpg";
import musaik from "../assets/spendenziel/musaik.jpeg"
import WidePhotoAndInfoView from "../components/WidePhotoAndInfoView";
import WideInfoView from "../components/WideInfoView";
import pic2 from "../assets/start/pic2.png";
import pic1 from "../assets/mitmachen2.jpg";
import PhotoAndText from "../components/PhotoAndText";
import Text from "../components/Text";
import { useEffect } from "react";
import UtilAnalytics from "../components/Analytics";

function MitmachenAusverkauft() {
    useEffect(() => {
        UtilAnalytics.page("/mitmachen");
    }, [])
    return (
        <div className="">
            <BgHolder
                mainImage={headerImage}
                // "https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg"
                s1={
                    <div>
                        <div className="visible lg:invisible self-center ml-4 text-white text-3xl mt-8 mb-12 md:mt-0 text-center md:text-left textShadow">

                        </div>

                        <WidePhotoAndInfoView
                            image={pic1}
                            title={"Jetzt Mitmachen"}
                            content={
                                <div>
                                    <div className="text-1xl">
                                        <p className="my-4">
                                            Mit dem Kauf eines Entenadoptionsscheins im Wert von 5 € unterstützen Sie unser Projekt!
                                        </p>
                                        <p className="my-4">
                                            Kaufen Sie einen oder möglichst viele Adoptionsscheine. Mit jedem Schein im Wert 5 Euro schicken Sie eine Ente in das Rennen und erhöhen Ihre Gewinnchancen.
                                        </p>
                                    </div>
                                </div>
                            }
                            button={{
                                text: "Zur Preisliste",
                                link: "/preisliste"
                            }}
                        />


                        <div className="pt-12"></div>

                    </div>
                }
                s2={
                    <div className="pb-48 lg:pb-12 w-full">
                        <WideInfoView
                            title={"Alle 7.000 Enten-Adoptionsscheine sind verkauft!"}
                            content={
                                <div>
                                    <div className="text-1xl">
                                        <p className="text-2xl mb-4">
                                            Ein großartiges Ergebnis! Herzlichen Dank an alle! Am Stand in der Altmarkt-Galerie und dem auf dem Stadtfest sowie per Briefpost gibt es somit keine Enten-Adoptionsscheine mehr.
                                        </p>
                                    </div>
                                </div>
                            }
                        />

                        <div className="pt-12"></div>

                    </div>
                }
                s3={
                    <div>

                        <WideInfoView
                            title={"Teilnahmeregeln"}
                            imgPadding={true}
                            content={
                                <div className="text-1xl">
                                    <p className="my-4">
                                        Zum Entenrennen zugelassen sind nur Enten, für die ein gültiger Entenadoptionsschein erworben wurde. Das „Entenrennen“ startet mit den nummerierten Gummienten an der Carolabrücke. Der Zieleinlauf ist eine Auffangeinrichtung an der Augustusbrücke. Jeder kann über den Erwerb von Entenadoptionsscheinen, die 5,– Euro pro Stück kosten, seine Gummiente in den Wettkampf schicken. Entenadoptionsscheine und Rennenten tragen identische Startnummern. 7.000 Enten werden maximal ins Rennen geschickt.
                                    </p>
                                    <p className="my-4">
                                        Sollte infolge extremen Niedrigwassers der Elbe aus sicherheitstechnischen Gründen der Einsatz der Mehrzweckboote des THW nicht möglich sein, dann werden die Preisträger am Ufer durch Ziehung der Rennenten aus einem dafür errichteten Pool ermittelt! Dazu befinden sich alle 7.000 Rennenten im Pool, von denen in Reihenfolge eine Glücksfee die Rennenten entnimmt und zur Protokollierung übergibt. Damit wird jedem Preis der Preisliste eine Rennentennummer zugeordnet.
                                    </p>
                                    <p className="my-4 pl-6 hidden">
                                        Die restlichen Preisträger werden dann von den Kameraden der Dresdner Berufsfeuerwehr Feuerwehr mittels Löschspritze mit gehörigem Wasserdruck aus dem Pool gespritzt und in Reihenfolge – zuerst die am weitesten vom Pool entfernten Rennenten - aufgelesen und deren Nummern protokolliert.
                                    </p>
                                    <p className="my-4 pl-6 hidden">
                                        Das ist die Ersatzvariante für das Rennen auf der Elbe, die leider auch 2018 und 2019 angewendet werden musste. Es bleibt zu wünschen, dass die Elbe in den kommenden jahren ausreichend viel Wasser führt, damit die Dresdner Entencups nicht mehr „in's Wasser fallen“ und auf der Elbe stattfinden können!
                                    </p>
                                    <p className="my-4">
                                        Das „Starten" der Rennenten erfolgt ausschließlich durch den Veranstalter. Von sonstigen nicht autorisierten Personen gestartete Enten sind nicht gewinnberechtigt.
                                    </p>
                                    <p className="my-4">
                                        Alle natürlichen und künstlichen Hindernisse sind unanfechtbarer Bestandteil der Rennstrecke. Der Veranstalter stellt die Reihenfolge des Zieleinlaufs fest. Die ersten zehn Enten, die den Zieleinlauf passieren, gewinnen in der Reihenfolge ihres Ankommens einen Preis. Dies gilt für die danach folgenden Enten entsprechend, sofern weitere Preise zur Verfügung stehen. Die Bekanntgabe der ersten 10 Gewinner und die Siegerehrung erfolgen am Veranstaltungstag nach Beendigung des Rennens. Zur Siegerehrung ist der gültige Entenadoptionsschein vorzulegen. Nicht anwesende Gewinner können sich in der Woche nach dem Entenrennen über ihren möglichen Gewinn im Internet auf diesen Seiten informieren.
                                    </p>
                                    <p className="my-4">
                                        Alle Rennenten sind und bleiben Eigentum des Veranstalters.
                                    </p>
                                    <p className="my-4">
                                        Entfällt das Rennen aufgrund höherer Gewalt oder anderen Gründen, für welche der Veranstalter nicht verantwortlich ist, verfallen alle eventuellen Rechte aus dem Entenadoptionsschein.
                                    </p>
                                    <p className="my-4">
                                        Der Rechtsweg ist ausgeschlossen. Für die Preisaushändigung ist ausschließlich die Vorlage des Entenadoptionsscheins erforderlich.
                                    </p>
                                </div>
                            }
                        />


                        <div className="my-36"></div>
                        <Sponsors small={true} />
                    </div>
                }

            >


            </BgHolder>
        </div>
    );
}

export default MitmachenAusverkauft;
