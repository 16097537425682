import ImgSlider from "./ImgSlider";
import ImgTable from "./ImgTable";

function Sponsors(props) {
    return (
        <div className="w-full flex flex-col items-center text-center">
            <div className="text-3xl  font-bold text-golden"> 
                Unsere Unterstützer
            </div>
            <div className="text-white font-bold mt-2 "> 
                Für die große Hilfe bei der Vorbereitung und Durchführung des Dresdner Entencup sagen wir herzlich Danke!
            </div>
            <div className="mt-2 w-full">
                {props.small && <ImgSlider small={props.small} />}
                {!props.small && <ImgTable />}
            </div>
        </div>
    );
}

export default Sponsors;
