
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import { getCookieConsentValue } from 'react-cookie-consent';

class UtilAnalytics {
    constructor() {
        console.log("anal constructor");
        this.handleAcceptCookie();
    }

    handleAcceptCookie = () => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            console.log("GOT TRUE");
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
                this.initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
            }
        }
    };

    initGA = (id) => {
        if (process.env.NODE_ENV === "production") {
            this.analytics = Analytics({
                app: 'Dresdner Entencup',
                plugins: [
                    googleAnalytics({
                        measurementIds: [id]
                    })
                ]
            })
        }
    };

    page(path) {
        if (this.analytics) {
            this.analytics.page({
                url: path
            });
        }
    }
}

const analyticsInstance = new UtilAnalytics();
export default analyticsInstance;