function HorizontalStats(props) {
    return (
        <div className="w-full flex flex-col lg:flex-row justify-between text-golden font-bold text-5xl m-auto">
            <div className="flex flex-col items-center flex-grow-0 w-full lg:w-1/3">
                <div>{props.item1}</div>
                <div className="text-3xl font-normal">{props.item1desc}</div>
            </div>
            <div className="flex flex-col items-center flex-grow-0 mt-8 lg:mt-0 w-full lg:w-1/3">
                <div>{props.item2}</div>
                <div className="text-3xl font-normal">{props.item2desc}</div>
            </div>
            <div className="flex flex-col items-center flex-grow-0 mt-8 lg:mt-0 w-full lg:w-1/3">
                <div>{props.item3}</div>
                <div className="text-3xl font-normal">{props.item3desc}</div>
            </div>
        </div>
    );
}

export default HorizontalStats;
