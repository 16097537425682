import { useState, useEffect } from "react";
import { MdSearch, MdOutlineDoubleArrow } from "react-icons/md";
import preisliste from "../assets/preisliste.json"

function PreislisteTable(props) {

    const maxPage = parseInt(preisliste.length / 10);

    const [currentPage, setCurrentPage] = useState(0);
    const [displayablePrizes, setDisplayablePrizes] = useState([]);
    const [prizeFound, setPrizeFound] = useState(undefined);

    function assignPrizesList() {
        setDisplayablePrizes(preisliste.slice(currentPage * 10, currentPage * 10 + 10))
    }

    useEffect(() => {
        assignPrizesList()
    }, [currentPage])

    function findPrize() {
        let inp = document.getElementById("prizeSearch");
        let found = preisliste.find((item) => item.startnummer === Number(inp.value));
        if (found !== undefined) {
            setPrizeFound(found)
        } else {
            setPrizeFound(null);
        }

    }

    function changeCurrentPage(num) {
        if (currentPage === 0 && num < 0) {
            return;
        }
        if (currentPage === maxPage && num > 0) {
            return;
        }
        setCurrentPage(currentPage + num);
    }





    return (
        <div className="bg-white rounded-2xl py-8">
            <div className="px-4">
                <div className="text-2xl text-golden w-full font-bold">
                    Alle Preise
                </div>
                <div className="text-blue-dark mt-4">
                    <p>
                        Das sind die vorläufigen Preise des 16. Dresdner Entencup 2024.
                    </p>
                    <p>
                        Die Startnummern der Gewinnerenten werden nach Abschluss des Entencups auf dieser Seite veröffentlicht.
                    </p>


                </div>
            </div>

            <div className={`${prizeFound !== undefined && prizeFound !== null ? "" : "hidden"}`}>
                {prizeFound && <div className="text-blue-dark">
                    <div className="hidden md:block">
                        <div className="grid grid-cols-6 p-4 font-bold">
                            <div>Platz</div>
                            <div className="col-span-2">Preis</div>
                            <div className="col-span-2">Gespendet</div>
                        </div>
                        <hr />
                        <div className="grid grid-cols-6 p-4">
                            <div>{prizeFound.platz}</div>
                            <div className="col-span-2">
                                <div>
                                    {prizeFound.preis}
                                </div>
                                {prizeFound.preis_extra && <div></div>}
                            </div>
                            <div className="col-span-2">{prizeFound.gespendet}</div>
                        </div>
                    </div>
                    <div className="block md:hidden">
                        <div className="grid grid-cols-2 p-4">
                            <div className="mb-4">Platz</div>
                            <div>{prizeFound.platz}</div>
                            <div className="mb-4">Preis</div>
                            <div className="">
                                <div>
                                    {prizeFound.preis}
                                </div>
                                {prizeFound.preis_extra && <div></div>}
                            </div>
                            <div className="mb-4">Gespendet</div>
                            <div className="">{prizeFound.gespendet}</div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className={`${prizeFound === null ? "" : "hidden"} p-4`}>
                <i>Es gibt leider keinen Preis für diese Startnummer</i>
            </div>

            <div className="mt-8 text-blue-dark">
                <div className="font-bold p-4">
                    {currentPage * 10 + 1} - {(currentPage * 10 + 11 > preisliste.length ? preisliste.length : currentPage * 10 + 10)} von {preisliste.length} Ergebnissen
                </div>
                <div className="pb-8">
                    <hr />
                    <div className="grid grid-cols-4 md:grid-cols-6 p-4 font-bold">
                        <div>Platz</div>
                        <div className="col-span-2  md:block text-right md:text-left">Preis</div>
                        <div className="col-span-2 hidden md:block">Gespendet</div>
                    </div>
                    <hr />
                    {displayablePrizes.map((prize, index) => {
                        return (
                            <div key={index}>
                                <div className="grid grid-cols-4 md:grid-cols-6 p-4">
                                    <div>{prize.platz}</div>
                                    <div className="col-span-2 whitespace-normal md:block pr-2">
                                        <div>
                                            {prize.preis}
                                        </div>
                                        {prize.preis_extra && <div></div>}
                                    </div>
                                    <div className="col-span-2 whitespace-normal  hidden md:block">{prize.gespendet}</div>
                                </div>
                                <hr />
                            </div>
                        )
                    })}
                </div>

            </div>

            <div className="flex flex-row justify-center items-center">
                <div
                    className={`${currentPage === 0 ? "disabled bg-blue-300 cursor-not-allowed" : "cursor-pointer bg-blue-light"} 
                    select-none p-1 md:p-4 text-white rounded-full mx-2 md:mx-8 font-bold flex flex-row items-center`}
                    onClick={(e) => { changeCurrentPage(-1) }}
                >
                    <MdOutlineDoubleArrow size={20} className="transform rotate-180" /> Vorgerige
                </div>
                <div className="text-md md:text-2xl font-bold text-blue-dark select-none">{currentPage + 1} / {parseInt(preisliste.length / 10) + 1}</div>
                <div
                    className={`${currentPage === maxPage ? "disabled bg-blue-300 cursor-not-allowed" : "cursor-pointer bg-blue-light"} 
                    select-none p-1 md:p-4 text-white  rounded-full mx-2 md:mx-8 font-bold flex flex-row items-center`}
                    onClick={(e) => { changeCurrentPage(1) }}
                >
                    Nächste <MdOutlineDoubleArrow size={20} />
                </div>

            </div>


        </div>
    );
}

export default PreislisteTable;