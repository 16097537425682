import BgHolder from "../components/BgHolder";
import HorizontalStats from "../components/HorizontalStats";
import NarrowPhotoAndInfoView from "../components/NarrowPhotoAndInfoView";
import Sponsors from "../components/Sponsors";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import TwoSidedView from "../components/TwoSidedView";
import headerImage from "../assets/header/entencup-2016-03-edit.jpg"
import PreislisteTable from "../components/PreislisteTable";
import NarrowInfoView from "../components/NarrowInfoView";
import GewinnerlisteTable from "../components/GewinnerlisteTable";
import { useEffect } from "react";
import UtilAnalytics from "../components/Analytics";

function Preisliste() {
    useEffect(() => {
        UtilAnalytics.page("/preisliste");
    }, [])
    return (
        <div className="">
            <BgHolder
                mainImage={headerImage}
                // "https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg"
                s1={
                    <div className="w-full">

                        <div className="my-12">
                        </div>
                        <div className="w-full m-auto text-white">
                            <div className="text-2xl text-golden w-full font-bold">
                                Wir danken allen Unterstützern für ihren Beitrag zum Erlös!
                            </div>
                            <div className="mt-8 md:mb-16">
                                <div className="text-1xl w-3/5">
                                    Der Dresdner Entencup ist ein besonderes Sommer-Event der Dresdner Lions und Leos und hat seit seinem Bestehen einen großen Bekanntheitsgrad erreicht. Inzwischen hat er viele Freunde und Unterstützer gewonnen. Spendensummen von insgesamt 443.449 Euro sprechen für sich!
                                    <br /><br /><br /><br />
                                </div>

                            </div>

                        </div>
                    </div>
                }
                s2={
                    <div className="pb-48 lg:pb-20 w-full">
                        <div className="w-full m-auto text-blue-dark">

                            <NarrowInfoView
                                title={"Werden Sie zu den Gewinnern des 16. Dresdner Entencups 2024 gehören?"}
                                content={
                                    <div>
                                        Die Aushändigung eines Preises erfolgt nur gegen Vorlage Ihres Enten-Adoptionsscheines.
                                        Die Preise können im Zeitraum vom 19.08. bis 30.09.2024 im Autohaus Leubnitz abgeholt werden:
                                        <div className="mt-8 mb-2">
                                            <TwoSidedView>
                                                <div>
                                                    <div>Autohaus Leubnitz GmbH</div>
                                                    <div>Neuostra 3 - 6, 01219 Dresden </div>
                                                    <br />
                                                    <div>Mo - Fr von 09:00 bis 16:00 Uhr</div>
                                                </div>
                                                <div>
                                                    <div>Telefonnummer:</div>
                                                    <div>0351 4706905</div>
                                                    <br />
                                                    <div>Email-Adresse:</div>
                                                    <div>
                                                        <a href="mailto:d.schulte@autohaus-leubnitz.de">d.schulte@autohaus-leubnitz.de</a>
                                                    </div>
                                                </div>
                                            </TwoSidedView>
                                        </div>
                                    </div>
                                }
                            />


                        </div>
                    </div>
                }
                s3={
                    <div>
                        {<PreislisteTable></PreislisteTable>}
                        {/*{<GewinnerlisteTable></GewinnerlisteTable>}*/}

                        <div className="pt-36"></div>


                        <Sponsors small={true} />
                    </div>
                }

            >


            </BgHolder>
        </div>
    );
}

export default Preisliste;
