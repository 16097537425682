import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

function NarrowPhotoAndInfoView(props) {
    return (
        <div className="bg-white rounded-xl m-auto flex flex-col h-full">
            <div className="w-full h-60">
                <img src={props.image} alt="Event" className={`object-cover w-full h-full rounded-tl-xl rounded-tr-xl ${props.imgPadding ? "p-4" : ""}`} />
            </div>
            <div className="w-full h-min p-4 text-blue-dark flex flex-col justify-between">
                <div>
                    <div className="text-xl text-golden font-bold mb-4">
                        {props.title}
                    </div>
                    {props.content}

                </div>
                {props.button && <div className="w-full">
                    <Link to={props.button.link}>
                        <div className="whitespace-nowrap mt-4 p-4 text-white bg-golden font-bold cursor-pointer rounded-2xl shadow-md flex-nowrap flex flex-row">
                            {props.button.text} <MdOutlineKeyboardArrowRight size={30} />
                        </div>
                    </Link>
                </div>}
                {props.link && <div className="mt-4">
                    <a href={props.link} target="_blank" rel="noreferrer">{props.link}</a>
                </div>}
            </div>

        </div>
    );
}

export default NarrowPhotoAndInfoView;
