import lionsLogo from "../assets/lionsLogo.png";
import fbLogo from "../assets/fbLogo.png";
import instaLogo from "../assets/instaLogo.png";
import settings from "../assets/settings.json";

function Footer() {
    return (
        <div className="w-4/5 m-auto pb-16">
            
            <div className="flex flex-col lg:flex-row justify-between text-white md:text-2xl sm:text-1xl">
                <div className="mb-10">

                    <div className="mb-5 font-bold">Kontakte</div>

                    <div className="mt-5">Arbeitsgemeinschaft Dresdner Entencup</div>
                    <div className="text-blue-light">
                        <a href="mailto:mail@dresdner-entencup.de">mail@dresdner-entencup.de</a>
                    </div>

                    <div className="mt-5">Herr Reinhard Hörig</div>
                    <p>Sprecher der Arbeitsgemeinschaft Dresdner Entencup</p>
                    <div><a href="tel:03514526937">Tel. 0351 / 4526937</a></div>
                    <div><a href="tel:01775182007">Mobil 0177 / 5182007</a></div>
                    <div className="text-blue-light">
                        <a href="mailto:r.hoerig@av-dresden-centrum.de">r.hoerig@av-dresden-centrum.de</a>
                    </div>


                    <div className="mt-5">Frau Ilva Partzsch</div>
                    <p>Pressekontakt</p>
                    <div><a href="tel:03514381311">Tel. 0351 / 4381311</a></div>
                    <div><a href="tel:01732031479">Mobil 0173 / 2031479</a></div>
                    <div className="text-blue-light">
                        <a href="mailto:partzsch@markenteam-dresden.de">partzsch@markenteam-dresden.de</a>
                    </div>
                    
                </div>
                <div className="mb-10">
                    <div className="font-bold mb-4">Spendenkonto</div>
                    <div>LIONS Club Hilfswerk</div>
                    <div>Jugendförderung Dresden Agenda 21 e.V.</div>
                    <div>Commerzbank Dresden</div>
                    <div>IBAN: DE39 8504 0000 0145 5450 01</div>
                    <div>BIC: COBADEFFXXX</div>
                    <div>Stichwort: “{settings.entencupNumber}. Dresdner Entencup”</div>
                </div>

                <div className="mb-10">
                    <div className="font-bold mb-2">Social Media</div>
                        <div className="flex">
                            <a href="https://en-gb.facebook.com/pg/entencup" target="_blank" rel="noreferrer">
                                <div><img src={fbLogo} height={50} width={50} alt="Facebook Link" /></div>
                            </a>
                            <a className="ml-4 mt-1" target="_blank" href="https://www.instagram.com/explore/tags/entencup/" rel="noreferrer">
                                <div><img height={40} width={40} src={instaLogo} alt="Instagram Link" /></div>
                            </a>
                    </div>
                </div>

                <div className="text-blue-light">
                    <div className="pb-4">
                        <a href="/impressum">Impressum</a>
                    </div>
                    <div className="">
                        <a href="/datenschutz">Datenschutz</a>
                    </div>
                </div>
            </div>


            <div className="mt-24 flex flex-col lg:flex-row text-white md:text-2xl sm:text-1xl items-center text-center md:text-left">
                <div>
                    <img src={lionsLogo} alt="lions logo" className="w-28" />
                </div>
                <div className="md:pl-8">
                    <div className="font-bold">
                        LIONS Club Hilfswerk
                    </div>
                    <div className="font-bold">
                        Jugendförderung Dresden Agenda 21 e.V.
                    </div>
                    <div>
                        c/o Detlef U. Müller-Greven
                    </div>
                    <div>
                        Anton-Graff-Straße 29, 01309 Dresden, Tel. 0351/44866-0
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
