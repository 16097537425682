import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Navbar from './components/Navbar';
import Start from './subpages/Start';
import Spendenziel from './subpages/Spendenziel';
import Preisliste from './subpages/Preisliste';
import Unterstuetzen from './subpages/Unterstuetzen';
import Mitmachen from './subpages/Mitmachen';
import MitmachenAusverkauft from './subpages/MitmachenAusverkauft';
import ScrollToTop from './components/ScrollToTop';
import Impressum from './subpages/Impressum';
import Datenschutz from './subpages/Datenschutz';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga4";
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import React, { useEffect } from 'react';

import UtilAnalytics from "./components/Analytics";



const menuLinks = [
    {
        name: "Impressum",
        link: "/impressum",
        view: Impressum,
        hiddenInMenu: true,
    },
    {
        name: "Datenschutz",
        link: "/datenschutz",
        view: Datenschutz,
        hiddenInMenu: true
    },
    {
        name: "Start",
        link: "/",
        view: Start,
        exact: true
    },
    {
        name: "Spendenziel",
        link: "/spendenziel",
        view: Spendenziel
    },
    {
        name: "Gewinnerliste",
        link: "/preisliste",
        view: Preisliste
    },
    {
        name: "Unterstützen",
        link: "/unterstuetzen",
        view: Unterstuetzen
    },
    {
        name: "Mitmachen",
        link: "/mitmachen",
        view: MitmachenAusverkauft
    }
]

const handleAcceptCookie = () => {
    UtilAnalytics.handleAcceptCookie()
}

function App() {
    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);
    return (
        <div className="App">
            <CookieConsent
                buttonText="Zulassen"
                buttonStyle={{ background: "white", color: "black", margin: "15px 15px 15px 0px" }}
                declineButtonText="Ablehnen"
                declineButtonStyle={{ background: "white", color: "black" }}
                style={{ background: "rgba(255, 170, 0, 1)", }}
                enableDeclineButton
                onAccept={handleAcceptCookie}
            >
                Wir verwenden Cookies, um die Zugriffe auf unsere Website zu analysieren. Erfahren Sie in unserer Datenschutzrichtlinie mehr darüber.
            </CookieConsent>
            <Router>
                <Navbar menu={menuLinks} />
                <ScrollToTop />
                <Switch>
                    {menuLinks.map((item, index) => {
                        return (
                            <Route exact={item.exact} path={item.link} key={index} component={item.view}>
                                {/* {item.view} */}
                            </Route>
                        )
                    })}
                    <Redirect to='/' />
                </Switch>

            </Router>
        </div>
    );
}

export default App;
