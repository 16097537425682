import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

function WideInfoView(props) {
    return (
        <div className="bg-white rounded-2xl m-auto flex flex-col lg:flex-row">
            <div className="w-full p-8 text-blue-dark flex flex-col justify-between">
                <div>
                    <div className="text-3xl text-golden font-bold mb-4">
                        {props.title}
                    </div>
                    {props.content}

                </div>
                {props.button &&
                    <Link to={props.button.link}>
                        <div className="w-min whitespace-nowrap mt-4 p-4 text-golden font-bold cursor-pointer rounded-2xl shadow-lg flex flex-row items-center">
                            {props.button.text} <MdOutlineKeyboardArrowRight size={30} />
                        </div>
                    </Link>
                }
            </div>

        </div>
    );
}

export default WideInfoView;
