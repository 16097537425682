import BgHolder from "../components/BgHolder";
import HorizontalStats from "../components/HorizontalStats";
import NarrowPhotoAndInfoView from "../components/NarrowPhotoAndInfoView";
import NarrowInfoView from "../components/NarrowInfoView";
import Sponsors from "../components/Sponsors";
import WidePhotoAndInfoView from "../components/WidePhotoAndInfoView";
import dlrg from "../assets/dlrg.jpg"
import headerImage from "../assets/header/entencup-2016-03-edit.jpg";
import stpauli from "../assets/spendenziel/stpauli.jpeg"
import musaik from "../assets/spendenziel/musaik.jpeg"
import malwina from "../assets/spendenziel/tabaluga-projekt.jpg"
import heilpaedagogik from "../assets/spendenziel/heilpaedagogik-bonnewitz.jpeg"
import tabaluga from "../assets/tabaluga.jpg"
import sonnenstrahl from "../assets/spendenziel/sonnenstrahl.jpg"
import { Link } from "react-router-dom"
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import TwoSidedView from "../components/TwoSidedView";
import { useEffect } from "react";
import UtilAnalytics from "../components/Analytics";

const Spendenziel = (props) => {
    useEffect(() => {
        UtilAnalytics.page("/spendenziel");
    }, [])
    return (
        <div className="">
            <BgHolder
                mainImage={headerImage}
                // "https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg"
                s1={
                    <div>
                        <div className="my-12"></div>

                        <TwoSidedView>
                            <div className="pr-2 text-white">
                                <div className="font-bold text-2xl text-golden">
                                    {/* Wer wird vom Erlös unterstützt? */}
                                    Spendenziel 2024
                                </div>
                                <div>
                                    <br />
                                    <p>
                                        Mit unserer diesjährigen Aktion unterstützen wir Dresdner Lions und Leos den <b>Sonnenstrahl e.V. Dresden, Förderkreis für krebskranke Kinder und Jugendliche</b>.
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div className="pl-2 flex flex-col items-center text-white">
                                <div className="w-full pt-10">
                                    <img src={sonnenstrahl} alt="malwina" className="w-full" />
                                </div>
                                <div className="mt-4">
                                    <Link to="/mitmachen">
                                        <div className="font-bold p-4 bg-golden rounded-full cursor-pointer flex flex-row">
                                            Jetzt mitmachen <MdOutlineKeyboardArrowRight size={30} />
                                        </div>
                                    </Link>

                                </div>

                            </div>
                        </TwoSidedView>
                        <div className="my-8 md:my-24 hidden">
                            <HorizontalStats
                                item1={""}
                                item1desc={""}
                                item2={"172"}
                                item2desc={"Tage verbleibend"}
                                item3={""}
                                item3desc={""}
                            />
                        </div>

                    </div>
                }
                s2={
                    <div className="pb-48 lg:pb-12 w-full">
                        <div className="w-full m-auto text-blue-dark">
                            <div className="text-2xl text-golden w-full font-bold">
                                Die Spendenerfolge vieler Jahre sprechen für sich!
                            </div>
                            <div className="mt-8 mb-16">
                                <TwoSidedView>
                                    <div>
                                        <div className="text-5xl">
                                            443.449 €
                                        </div>
                                        <div className="text-2xl mt-4">
                                            Spenden seit Bestehen des Dresdner Entencups!
                                        </div>
                                    </div>
                                    <div>
                                        Der Dresdner Entencup ist eine Erfolgsgeschichte! Er findet zum 16. Male statt und in den vergangenen 15 Jahren konnten Spenden von insgesamt 443.449 EURO gemeinnützigen Vereinen für die Verwirklichung wichtiger Projekte der Kinder- und Jugendförderung übergeben werden.
                                    </div>
                                </TwoSidedView>
                            </div>

                        </div>
                    </div>
                }
                s3={
                    <div>
                        <div className="flex flex-col lg:flex-row mb-8">
                            <div className="w-full lg:w-1/2 lg:pr-4">
                                <NarrowPhotoAndInfoView
                                    image={heilpaedagogik}
                                    title={"2023: Heilpädagogik - Bonnewitz"}
                                    content={
                                        <div>
                                            <div>
                                                Der 15. Dresdner Entencup 2023 unterstützte die Projekte der Betriebsgesellschaft Heilpädagogik - Bonnewitz gemeinnützige GmbH.
                                                <br /> <br />
                                            </div>
                                        </div>
                                    }
                                    link="https://www.heilpaedagogik-bonnewitz.de"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row mb-8">
                            <div className="w-full lg:w-1/2 lg:pr-4">
                                <NarrowPhotoAndInfoView
                                    image={malwina}
                                    title={"2022: Malwina e.V. integrative Kita „Tabaluga“ "}
                                    content={
                                        <div>
                                            <div>
                                                Der 14. Dresdner Entencup 2022 unterstützte die integrative Kita „Tabaluga“ der Malwina e.V., um einen Bewegungsraum für die Kinder umzugestalten.
                                                <br /> <br />
                                            </div>
                                        </div>
                                    }
                                    link="https://www.malwina-dresden.de"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-4 mt-4 lg:mt-0">
                                <NarrowPhotoAndInfoView
                                    image={musaik}
                                    title={"2021: Musaik Grenzenlos Musizieren e.V."}
                                    content={
                                        <div>
                                            <div>
                                                Der 13. Dresdner Entencup 2021 unterstützte den Musaik Grenzenlos Musizieren e.V., um weitere dringend benötigte Musikinstrumente anzuschaffen.
                                            </div>
                                        </div>
                                    }
                                    link="https://www.musaik.eu"
                                />
                            </div>
                        </div>


                        <div className="flex flex-col lg:flex-row mb-8">
                            <div className="w-full lg:w-1/2 lg:pr-4">
                                <NarrowInfoView
                                    image={dlrg}
                                    title={"2019: Deutschen Lebens- Rettungs- Gesellschaft"}
                                    content={
                                        <div>
                                            <div>
                                                Im Jahre 2019 brachte der 12. Dresdner Entencup wieder eine hohe Spendensumme ein, die der Deutschen Lebens- Rettungs- Gesellschaft Bezirk Dresden e.V. für ihre Jugendförderung überreicht werden konnte!
                                            </div>
                                        </div>
                                    }
                                    link="https://dresden.dlrg.de/"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-4 mt-4 lg:mt-0">
                                <NarrowInfoView
                                    image={stpauli}
                                    title={"2018: TheaterRuine St. Pauli e.V."}
                                    content={
                                        <div>
                                            <div>
                                                Der 11. Dresdner Entencup unterstütze 2018 mit der Spende den TheaterRuine St. Pauli e.V. für das Projekt „TheaterKids“. Inzwischen fand die erste Aufführung des Kinder- und Jugendensembles statt.
                                            </div>
                                        </div>
                                    }
                                    link="https://www.pauliruine.de/ "
                                />
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row mb-8">
                            <div className="w-full lg:w-1/2 lg:pr-4">
                                <NarrowInfoView
                                    image={dlrg}
                                    title={"2017: SV Motor Mickten-Dresden e.V."}
                                    content={
                                        <div>
                                            <div>
                                                Der Reinerlös des 10. Dresdner Entencups im Jahre 2017 ging an den SV Motor Mickten-Dresden e.V. für E- Rolli- Fußball. Mit der Spende konnten für die körperlich Schwerstbehinderten extra für diesen Sport entwickelte Behindertenfahrstühle angeschafft werden!
                                            </div>
                                        </div>
                                    }
                                    link="https://www.motor-mickten.de/"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-4 mt-4 lg:mt-0">
                                <NarrowInfoView
                                    image={stpauli}
                                    title={"2016: Kinder- und Jugendbauernhof Nickern e.V."}
                                    content={
                                        <div>
                                            <div>
                                                In 2016 gab die Arbeitsgemeinschaft Dresdner Entencup noch einmal von allen Bewerbern dem Kinder- und Jugendbauernhof Nickern e.V. für die Sanierung des Bauernhauses (Rohbau) die meisten Stimmen!
                                                <br /><br />
                                            </div>
                                        </div>
                                    }
                                    link="https://www.kjb-nickern.de/"
                                />
                            </div>
                        </div>


                        <div className="flex flex-col lg:flex-row mb-8">
                            <div className="w-full lg:w-1/2 lg:pr-4">
                                <NarrowInfoView
                                    image={dlrg}
                                    title={"2015: Rollimaus"}
                                    content={
                                        <div>
                                            <div>
                                                2015 war das Jahr des 8. Dresdner Entencups, in dem der Verein zur Förderung körperbehinderter und chronisch kranker Kinder und Jugendlicher e.V. - auch Rollimaus genannt – die Spende für den Bau einer Rollstuhlschaukel einsetzen konnte!
                                            </div>
                                        </div>
                                    }
                                    link="https://www.rollimaus.de/"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-4 mt-4 lg:mt-0">
                                <NarrowInfoView
                                    image={stpauli}
                                    title={"2014: Kindervereinigung Dresden e.V."}
                                    content={
                                        <div>
                                            <div>
                                                Im Jahre 2014, dem 7. Jahr des Dresdner Entencups, erhielt der Kinderzirkus KAOS der Kindervereinigung Dresden e.V. die Spende.
                                                <br /><br /><br />
                                            </div>
                                        </div>
                                    }
                                    link="https://kindervereinigung-dresden.de/"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row mb-8">
                            <div className="w-full lg:w-1/2 lg:pr-4">
                                <NarrowInfoView
                                    image={dlrg}
                                    title={"2013: Kinder- und Jugendhaus „Louise“"}
                                    content={
                                        <div>
                                            <div>
                                                Der Verein Malwina e.V. bekam die Spende des 6. Dresdner Entencups 2013 für die kinder- und jugendgerechte Neugestaltung des Gartens am Kinder- und Jugendhaus „Louise“ und der KITA.
                                                <br /><br /><br />
                                            </div>
                                        </div>
                                    }
                                    link="https://www.malwina-dresden.de/"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-4 mt-4 lg:mt-0">
                                <NarrowInfoView
                                    image={stpauli}
                                    title={"2012: Sternwarte Gönnsdorf"}
                                    content={
                                        <div>
                                            <div>
                                                Der 5. Dresdner Entencup unterstützte 2012 die Interessengemeinschaft der Sternwarte Gönnsdorf. Hier wurde die Spende für die Errichtung eines sozialen Anbaus verwendet, in dem sich die Kinder und Jugendlichen während ihrer winterlichen Sternbeobachtungen aufwärmen können.
                                            </div>
                                        </div>
                                    }
                                    link="https://www.sternwarte-goennsdorf.de/"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row mb-8">
                            <div className="w-full lg:w-1/2 lg:pr-4">
                                <NarrowInfoView
                                    image={dlrg}
                                    title={"2011: Gut Gamig e.V."}
                                    content={
                                        <div>
                                            <div>
                                                Der gemeinnützige Verein Gut Gamig e.V. erhielt im Jahre 2011 die Spende des 4. Dresdner Entencups zum Bau einer Lehrküche, in der behinderte Jugendliche eine Teilausbildung erlangen!
                                                <br /><br /><br />
                                            </div>
                                        </div>
                                    }
                                    link="https://www.gut-gamig.de/"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-4 mt-4 lg:mt-0">
                                <NarrowInfoView
                                    image={stpauli}
                                    title={"2010: Kinder- und Jugendbauernhof Nickern e.V."}
                                    content={
                                        <div>
                                            <div>
                                                Spendenziel des 3. Dresdner Entencups im Jahre 2010 war der Kinder- und Jugendbauernhof Nickern e.V.! Mit der Spendensumme konnten wichtige Planungs- und Sicherungsarbeiten am ehemaligen und inzwischen baufälligen Bauernhaus vorgenommen werden, aus dem einmal ein Kinderbauernhaus entstehen wird.
                                            </div>
                                        </div>
                                    }
                                    link="https://www.kjb-nickern.de/"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row mb-8">
                            <div className="w-full lg:w-1/2 lg:pr-4">
                                <NarrowInfoView
                                    image={dlrg}
                                    title={"2009: Psychosoziale Trägerverein Sachsen e.V."}
                                    content={
                                        <div>
                                            <div>
                                                Im Jahre 2009 erhielt die Spendensumme der Psychosoziale Trägerverein Sachsen e.V. für das Projekt „KiElt“. Dieses Projekt hatte die Betreuung von Kindern zum Inhalt, die in Familien psychisch kranker Eltern aufwachsen und oft darunter leiden!
                                            </div>
                                        </div>
                                    }
                                    link="https://www.ptv-sachsen.de/"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-4 mt-4 lg:mt-0">
                                <NarrowInfoView
                                    image={stpauli}
                                    title={"2008: Kindervereinigung Dresden e.V."}
                                    content={
                                        <div>
                                            <div>
                                                Mit dem 1. Dresdner Entencup im Jahre 2008 wurde das Projekt „Ferien für alle Kinder“ der Kindervereinigung Dresden e.V. unterstützt. 86 bedürftigen Kindern konnte ein kostenfreier Ferienaufenthalt ermöglicht werden!
                                            </div>
                                        </div>
                                    }
                                    link="https://kindervereinigung-dresden.de/"
                                />
                            </div>
                        </div>

                        <div className="pt-20"></div>

                        <div className="mt-8">
                            <Sponsors small={true} />

                        </div>
                    </div>
                }

            >


            </BgHolder>
        </div>
    );
}

export default Spendenziel;
