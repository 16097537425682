import { useState, useEffect } from "react";
import sponsors from "../assets/sponsors.json";
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from "react-icons/md";


function ImgSlider(props) {

    const data = sponsors;

    const [rolling, setRolling] = useState(true)

    function calculateImagesNumber() {
        return window.innerWidth > 785 ? 2 : 0;
    }


    const imagesSlideCount = calculateImagesNumber();

    const [currentMidImage, setCurrentMidImage] = useState(0);

    function adjustOffset(num, manual) {
        if (manual) {
            setRolling(false);
        }
        var newIndex = (currentMidImage + num) >= 0 ? (currentMidImage + num) % data.length : data.length - Math.abs(currentMidImage + num);
        setCurrentMidImage(newIndex);
    }

    function getIndices() {
        var indices = [];
        for (let index = 0-imagesSlideCount; index <= imagesSlideCount; index++ ){
            if (currentMidImage + index < 0) {
                indices.push(data.length - currentMidImage + index);
            } else if (currentMidImage + index >= data.length) {
                indices.push((currentMidImage + index) % data.length);
            } else {
                indices.push(currentMidImage + index);
            }
        }
        return indices;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (rolling) {
                adjustOffset(1, false)
            }
        }, 3000);
        return () => {
            clearInterval(interval)
        }
    })



    return (
        <div className="relative w-full z-50">
        
            <div className="w-full absolute top-0 left-0 flex flex-row justify-between text-white h-full select-none z-50">
                <div className="h-full bg-gradient-to-r from-blue-medium to-transparent pr-8 pl-4 cursor-pointer flex items-center z-50" onClick={() => { adjustOffset(-1, true) }}>
                    <MdOutlineKeyboardArrowLeft size={60} className="text-golden"/>
                </div>
                <div className="h-full bg-gradient-to-l from-blue-medium to-transparent pl-8 pr-4 cursor-pointer flex items-center z-50" onClick={() => { adjustOffset(1, true) }}>
                    <MdOutlineKeyboardArrowRight size={60} className="text-golden"/>
                </div>
            </div>
            <div className="w-full flex flex-row items-center">
                {
                    getIndices().map((index) => {
                        return (<div className={`select-none p-2 w-full lg:w-1/3 object-contain `} key={index}>
                            <div className="bg-blue-dark w-full flex flex-col items-center p-1 h-28 justify-center cursor-pointer ">
                                <img src={data[index].img} alt="sponsor" className={`object-cover max-w-full max-h-full overflow-hidden `} />
                            </div>
                        </div>)

                    })
                }
            </div>
        </div>
    );
}

export default ImgSlider;
