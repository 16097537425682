import BgHolder from "../components/BgHolder";
import TwoSidedView from "../components/TwoSidedView";
import NarrowInfoView from "../components/NarrowInfoView";
import headerImage from "../assets/header/entencup-2016-03-edit.jpg";
import musaik from "../assets/spendenziel/musaik.jpeg"
import gallery from "../assets/gallery.json" // here should be names of image files.
import { useEffect } from "react";
import UtilAnalytics from "../components/Analytics";


function Impressum() {
    useEffect(() => {
        UtilAnalytics.page("/impressum");
    }, [])

    return (
        <div className="">
            <BgHolder
                mainImage={headerImage}
                // "https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg"
                s1={
                    <div className="z-50 mb-24">
                        <div className="my-12"></div>
                        <NarrowInfoView
                            title={"Impressum"}
                            content={
                                <div>
                                    <p class="mb-2">
                                        Der Dresdner Entencup ist eine Initiative von Dresdner Lions Clubs und der Dresdner Leo Clubs. Er wird ausgerichtet von einer Arbeitsgemeinschaft aus deren Vertretern.
                                    </p>
                                    <p class="mb-2">
                                        <ul>
                                            <li>Sprecher: Reinhard Hörig</li>
                                            <li>Öffentlichkeitsarbeit: Ilva Partzsch</li>
                                            <li>Planung/ Vorbereitung/ Ablauforganisation: Reinhard Hörig</li>
                                            <li>Vertrieb / Verkaufsorganisation: Susann Schmutzer</li>
                                            <li>Spender und Sponsoren: Regina Lenz</li>
                                            <li>Finanzen: Regina Lenz</li>
                                        </ul>
                                    </p>
                                    <div class="mb-6"></div>
                                    <p class="mb-2">
                                        Rechtsförmlicher Veranstaltungsträger und inhaltlich Verantwortlicher gemäß § 6 MDStV ist der LIONS Club Hilfswerk Jugendförderung Dresden Agenda 21 e.V.
                                    </p>
                                    <p class="mb-2">
                                        c/o Detlef U. Müller-Greven, <br />
                                        Anton-Graff-Straße 29, <br />
                                        01309 Dresden, <br />
                                        Tel. 0351/44866-0, <br />
                                        {/*
                                            <a className="text-blue-light" href="mailto:mail@dresdner-entencup.de">mail@dresdner-entencup.de</a>
                                            */}
                                    </p>
                                </div>
                            }
                        />
                    </div>
                }
                s2={
                    <div className="mb-24">
                    </div>
                }
                s3={
                    <div>
                    </div>
                }

            >


            </BgHolder>
        </div>
    );
}

export default Impressum;
