import BgHolder from "../components/BgHolder";
import HorizontalStats from "../components/HorizontalStats";
import NarrowPhotoAndInfoView from "../components/NarrowPhotoAndInfoView";
import Sponsors from "../components/Sponsors";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import TwoSidedView from "../components/TwoSidedView";
import headerImage from "../assets/header/entencup-2016-03-edit.jpg";
import PreislisteTable from "../components/PreislisteTable";
import stpauli from "../assets/spendenziel/stpauli.jpeg"
import musaik from "../assets/spendenziel/musaik.jpeg"
import pic1 from "../assets/start/pic1.jpeg";
import { Link } from "react-router-dom"
import NarrowInfoView from "../components/NarrowInfoView";
import JustButtonView from "../components/JustButtonView";
import WidePhotoAndInfoView from "../components/WidePhotoAndInfoView";
import { useEffect } from "react";
import UtilAnalytics from "../components/Analytics";

function Unterstuetzen() {
    useEffect(() => {
        UtilAnalytics.page("/unterstuetzen");
    }, [])

    return (
        <div className="">
            <BgHolder
                mainImage={headerImage}
                // "https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg"
                s1={
                    <div>
                        <div className="visible lg:invisible self-center ml-4 text-white text-3xl mt-8 mb-12 md:mt-0 text-center md:text-left textShadow">

                        </div>

                        <WidePhotoAndInfoView
                            image={pic1}
                            title={"Wir suchen Spender und Sponsoren!"}
                            content={
                                <div>
                                    <div className="text-1xl">
                                        <p className="my-4">
                                            Bitte unterstützen Sie den Dresdner Entencup als Spender oder Sponsor! Wir brauchen Ihre Hilfe!
                                        </p>
                                        <p className="my-4">
                                            Ausführlichere Informationen finden Sie in unserer Spenden- und Sponsoringanfrage.
                                        </p>
                                        <p className="my-4">
                                            Haben Sie sich entschieden, das Förderprojekt zu unterstützen, so laden Sie bitte die Spender- oder
                                            Sponsoren-Erklärung herunter und senden Sie diese bitte ausgefüllt an die angegebene Faxnummer oder
                                            per E- Mail an mail@dresdner-entencup.de
                                        </p>
                                    </div>
                                </div>

                            }
                        />


                        <div className="pt-12"></div>


                        <div className="pt-2"></div>
                        <JustButtonView
                            image={pic1}
                            title={""}
                            content={
                                <div></div>
                            }
                            button={{
                                text: "Spenden- und Sponsoringanfrage",
                                link: "/public/downloads/spenden-und-sponsorenanfrage.pdf",
                                external: true
                            }}
                        />
                        <div className="pt-2"></div>
                        <JustButtonView
                            image={pic1}
                            title={""}
                            content={
                                <div></div>
                            }
                            button={{
                                text: "Vordruck Spender-Erklärung",
                                link: "/public/downloads/spender-erklaerung.pdf",
                                external: true
                            }}
                        />
                        <div className="pt-2"></div>
                        <JustButtonView
                            image={pic1}
                            title={""}
                            content={
                                <div></div>
                            }
                            button={{
                                text: "Vordruck Sponsoren-Erklärung",
                                link: "/public/downloads/sponsoren-erklaerung.pdf",
                                external: true
                            }}
                        />

                        <div className="pt-12"></div>


                    </div>
                }

                s2={
                    <div className="pb-48 lg:pb-12 w-full">
                        <div className="mt-8">
                            <Sponsors small={true} />
                        </div>
                    </div>
                }
                s3={
                    <div>

                    </div>
                }

            >


            </BgHolder>
        </div>
    );
}

export default Unterstuetzen;
