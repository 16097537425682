import { MdOutlineKeyboardArrowRight, MdDownload } from "react-icons/md";
import { Link } from "react-router-dom";

function JustButtonView(props) {
    return (
        <div className="bg-white rounded-xl m-auto flex flex-col h-full">
            <div className="w-full h-min p-2 text-blue-dark flex flex-col justify-between">
                {props.button && props.button.external &&
                    <a href={props.button.link} alt="download" target="_blank" rel="noreferrer">
                        <div className="whitespace-pre-line mt-0 p-4 text-white bg-golden font-bold cursor-pointer rounded-2xl shadow-md flex-nowrap flex flex-row items-center space-x-2">
                            <div>
                                <MdDownload size={30} />
                            </div>
                            <div>
                                {props.button.text}
                            </div>
                        </div>
                    </a>
                }
                {props.link && <div className="mt-0 pt-0">
                    <a href={props.link} target="_blank" rel="noreferrer">{props.link}</a>
                </div>}
            </div>

        </div>
    );
}

export default JustButtonView;
