import BgHolder from "../components/BgHolder";
import NarrowPhotoAndInfoView from "../components/NarrowPhotoAndInfoView";
import Sponsors from "../components/Sponsors";
import WidePhotoAndInfoView from "../components/WidePhotoAndInfoView";
import WarningView from "../components/WarningView";
import WideInfoView from "../components/WideInfoView";
import pic1 from "../assets/enten-fallen.jpg";
import pic6 from "../assets/spendenziel-vergebung.jpg";
import pic7 from "../assets/portrait-enten-silhouette.jpg"
import pic8 from "../assets/eindruck.jpg"
import pic9 from "../assets/spendenuebergabe.jpg"
import pic10 from "../assets/helfen.jpg"
import headerImage3 from "../assets/header/entencup-2016-03-edit.jpg"
import settings from "../assets/settings.json";
import CountdownTimer from "../components/CountdownTimer";
import HorizontalStats from "../components/HorizontalStats";
import UtilAnalytics from "../components/Analytics";
import react, { useEffect } from "react";

const nextEvent = new Date('2022-08-21T23:59:00');
function Start(props) {

    useEffect(() => {
        UtilAnalytics.page("/");
    }, [])
    return (
        <div className="">
            <BgHolder
                mainImage={headerImage3}

                s1={
                    <div>

                        <div className="self-center ml-4 text-white text-3xl mt-8 mb-12 md:mt-0 text-center md:text-left textShadow">
                            <div className="lg:hidden">
                                <div>
                                    {settings.entencupNumber}.
                                </div>
                                <div>
                                    DRESDNER
                                </div>
                                <div>
                                    ENTENCUP
                                </div>
                            </div>
                        </div>

                        <WidePhotoAndInfoView
                            image={pic1}
                            title={"Der Dresdner Entencup"}
                            content={
                                <div>
                                    <div className="text-1xl">
                                        <p>
                                            Als eigenständige und das Dresdner Stadtfest begleitende Veranstaltung bereichern
                                            die Lions und Leos mit dem Dresdner Entencup wirkungsvoll das Festprogramm und locken
                                            jedes Jahr viele Besucher des Stadtfestes an beide Ufer zwischen Carolabrücke und Augustusbrücke!
                                        </p>
                                        <br />
                                        <p>
                                            Die Hauptakteure sind 7.000 gelbe Plastikenten, die wie Hochleistungssportler mit der Startnummer
                                            ihres Paten auf der Brust in einem spannenden Wettrennen auf der Elbe, von der
                                            Carolabrücke bis zum Zielpunkt Augustusbrücke, schwimmen.
                                        </p>
                                        <br />
                                        <p>
                                            Mit dem Kauf eines Entenadoptionsscheins im Wert von 5 € unterstützen Sie unser Projekt
                                            und senden eine Ente ins Rennen! Vielleicht gewinnt Ihre Ente und einer der vieler Preise geht an Sie.
                                        </p>
                                        <br />
                                        <p>
                                            Der 16. Dresdner Entencup 2024 unterstützt den 	Sonnenstrahl e.V. Dresden, Förderkreis für
                                            krebskranke Kinder und Jugendliche.
                                        </p>
                                    </div>
                                </div>
                            }
                            button={{
                                text: "Mehr über’s Projekt",
                                link: "/spendenziel"
                            }}
                        />

                        <div className="p-8"></div>

                        {/*
                        <div className="w-full">
                            <WarningView
                                title={"Änderung des Rennablaufs!"}
                                content={
                                    <div>
                                        <div className="pb-5">
                                        Der Rennablauf muss aus sicherheitstechnischen Gründen leider geändert werden!
                                        Das Rennen findet nicht auf der Elbe statt, sondern auf dem Neustadtufer
                                        vor der Augustusbrücke in einem dafür hergerichteten und mit Elbewasser gefülltem Pool.
                                        In diesem werden die Preisträger aus 7.000 Rennenten ermittelt! Der Start erfolgt unverändert 15:15 Uhr am Pool!
                                        <br /><br />
                                        Alle 7.000 Entenadoptionsscheine sind verkauft!
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        */}

                        {/*
                        <div className="my-8 md:my-24">
                            <HorizontalStats
                                item1={""}
                                item1desc={""}
                                item2={<CountdownTimer date={nextEvent}/>}
                                item2desc={"Tage verbleibend"}
                                item3={""}
                                item3desc={""}
                            />
                        </div>
                        */}




                    </div>
                }
                s2={
                    <div className="pb-48 lg:pb-12 w-full">
                        <div className="w-full m-auto">

                            {/*
                            <WidePhotoAndInfoView
                                image={pic7}
                                title={"Der 15. Dresdner Entencup 2023"}
                                content={
                                    <div>
                                        <div className="text-1xl">
                                            <p className="text-2xl font-bold mb-4">
                                                Am 20.08.2023, Start 15:15 Uhr am Neustadtufer vor der Augustusbrücke
                                            </p>
                                            <p>
                                            Mit unserer diesjährigen Aktion wollen wir Dresdner Lions und Leos den Verein Malwina e.V. und hier insbesondere die integrative Kita „Tabaluga“ in der Dresdner Johannstadt unterstützen. Die Kita bietet Platz für über 160 Kinder und zeichnet sich durch eine besonders hohe Internationalität aus.
                                            <br/><br/>
                                            Für viele der Kinder mit herausfordernden Lebensbedingungen ist Bewegung und das Ausagieren von Spannungen existenziell. Bewegung in all seinen Formen wie Klettern, Hangeln, Kriechen, Rutschen, Springen und Balancieren sind wichtige Stationen zur Kräftigung des Muskelaufbaus und sich in Gleichgewicht, Motorik, Konzentration und Ausdauer zu üben.
                                            <br/><br/>
                                            Um den Erfordernissen und Bedürfnissen der Kinder gerecht zu werden, möchte die Kita einen Raum zu einem Bewegungsraum umgestalten.
                                            <br/><br/>
                                            Das Konzept dafür wurde mit dem Lehrstuhl für Sportwissenschaft der Universität Würzburg und dem Institut für Bewegungsbildung und Bewegungsforschung für Kindergärten entwickelt und 2017 mit dem Zertifikat „Bewegte Innovation“ ausgezeichnet.
                                            </p>
                                        </div>
                                    </div>
                                }
                                button={{
                                    text: "Jetzt mitmachen!",
                                    link: "/mitmachen"
                                }}
                            />
                            */}

                            <WideInfoView
                                title={"16. Dresdner Entencup konnte nicht starten!"}
                                content={
                                    <div>
                                            <p>
                                              Der 16. Dresdner Entencup musste am Stadtfestsonntag wegen einer zur Startzeit nahenden Gewitterfront erst verschoben und nach einer halben Stunde des ergebnislosen Wartens unter der schützenden Augustusbrücke letztendlich abgebrochen werden. Der Schutz der Gesundheit und des Lebens der Zuschauer und der Durchführenden ließ keine andere Entscheidung zu!
                                            </p>
                                            <br />
                                            <p>
                                              Wir werden über eine erneute Vorbereitung und Durchführung des Rennens beraten und auf der Homepage und in der regionalen Presse berichten.
                                              Die Nummern der erworbenen Entenadoptionscheine behalten ihre Gültigkeit! Und auch an der Liste der Gewinne ändert sich nichts!
                                            </p>
                                    </div>
                                }
                            />


                            <div className="pt-12"></div>


                            <WidePhotoAndInfoView
                                image={pic8}
                                title={"Ein Rückblick auf 2023"}
                                content={
                                    <div>
                                        <div className="text-1xl">
                                            <p>
                                                Mit unserer vorjährigen Aktion unterstützten wir Dresdner Lions und Leos
                                                das Wohnheim der <b>Heilpädagogik-Bonnewitz gemeinnützige GmbH</b>.
                                                Hier wohnen 48 seelenpflegebedürftige Kinder und Jugendliche.
                                                Geschulte Heil- pädagogen und Pflegekräfte bieten den Kindern
                                                und Jugendlichen ein heimisches Umfeld, geben ihnen die Liebe
                                                und die Aufmerksamkeit,	Sicherheit,	Geborgenheit und Selbstvertrauen.
                                                Mit der Spendensumme, dem Reinerlös des 15. Dresdner Entencups 2023,
                                                werden drei Herzensprojekte für Kinder und Jugendlichen finanzierent:
                                                <ol>
                                                    <li>Urlaub s fahrten  für die Kinder und Jugendlichen</li>
                                                    <li>Heilpädagogisches Reiten</li>
                                                    <li>Ausgestaltung eines Musikraumes</li>
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                }
                            />

                            <div className="pt-12"></div>

                            <WidePhotoAndInfoView
                                image={pic6}
                                title={"Mit Freude spenden"}
                                content={
                                    <div>
                                        <div className="text-1xl">
                                            <p>
                                                Die Initiatoren des Events sind die Mitglieder der Dresdner Lions-Clubs und Leo-Clubs.
                                                Wir organisieren ehrenamtlich dieses wunderbare Event und werden dabei seit vielen Jahren
                                                von der Berufsfeuerwehr, dem Technischen Hilfswerk, der Polizei, dem Wasserstraßen- und
                                                Schifffahrtsamt Dresden, der Wasserschutzpolizei, den zuständigen Abteilungen der
                                                Landeshauptstadt und vielen weiteren Helfern, Firmen, und Vereinen unterstützt.
                                            </p>
                                            <br />
                                            <p>
                                                "We serve – wir dienen."
                                            </p>
                                            <br />
                                            <p>
                                                Lions ist die größte Hilfsorganisation weltweit. Über 1,4 Millionen Mitglieder engagieren
                                                sich für hilfsbedürftige Menschen ehrenamtlich und uneigennützig. Hier, in Dresden,
                                                gibt es Lions-Clubs und Leo-Clubs (junge Mitglieder bis 30 Jahre).
                                            </p>
                                        </div>
                                    </div>
                                }
                            />

                        </div>
                    </div>
                }
                s3={
                    <div className="pb-48 lg:pb-12 pt-10 w-full">
                        <div className="w-full m-auto">


                            <WidePhotoAndInfoView
                                image={pic9}
                                title={"Bewerbungen für den 17. Dresdner Entencup 2025"}
                                content={
                                    <div>
                                        <div className="text-1xl">
                                            <p>
                                                15 Jahre Dresdner Entencup ist eine Erfolgsgeschichte der Dresdner Lions Clubs und ihres Leo Clubs. Bis zum 31.12.2024 können sich gemeinnützige Vereine mit ihren bedeutenden Projekten der Kinder- und Jugendförderung für die Spende des 17. Dresdner Entencups 2025 bewerben, die wieder mehr als 30.000 Euro betragen wird.
                                            </p>
                                            <br />
                                            <p>
                                                Gemeinnützige Vereine haben jetzt die Möglichkeit, sich für den 17. Dresdner Entencup 2025 mit Projekten der Kinder- und Jugendförderung zu bewerben! Die Bewerbungen sind per E-Mail an <text class="text-golden"> mail@dresdner-entencup.de </text> bis spätetens 31.12.2024 an die Arbeitsgemeinschaft Dresdner Entencup zu richten.
                                            </p>
                                        </div>
                                    </div>
                                }
                            />

                            <div className="pt-14"></div>

                            <WidePhotoAndInfoView
                                image={pic10}
                                title={"Wir suchen fortlaufend Spender und Sponsoren"}
                                content={
                                    <div>
                                        <p>
                                            Wir suchen Spender und Sponsoren, die dem 16. Dresdner Entencup 2024,
                                            aber auch für die nachfolgenden Jahre dem Gemeinschaftsprojekt
                                            Dresdner Lions und Leos zum Erfolg verhelfen wollen.
                                        </p>
                                        <br />
                                        <p>
                                            Den vielen Spendern und Sponsoren, die uns über Jahre maßgeblich unterstützt haben, sagen wir Vielen Dank!
                                        </p>
                                    </div>
                                }
                                button={{
                                    text: "So geht’s",
                                    link: "/unterstuetzen"
                                }}
                            />

                            <div className="pt-20"></div>

                            <div>
                                <Sponsors small={true} />

                            </div>

                            <div className="pt-20"></div>


                        </div>
                    </div>


                }

            >


            </BgHolder>
        </div>
    );
}

export default Start;
