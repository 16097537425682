import sponsors from "../assets/sponsors.json";

function ImgTable(props) {
    console.log(sponsors);
    return (
        <div className="w-full flex flex-row flex-wrap">
            {sponsors.map((sponsor, index) => {
                return (
                    <div className="p-4 w-full lg:w-1/4 object-contain " key={index}>
                        <a href={sponsor.link} target="_blank" rel="noreferrer" >
                            <div className="bg-blue-dark w-full flex flex-col items-center p-1 h-40 justify-center cursor-pointer">
                                <img src={sponsor.img} alt="sponsor" className="object-cover max-w-full max-h-full overflow-hidden" />
                            </div>
                        </a>
                    </div>
                )
            })}
        </div>
    );
}

export default ImgTable;
