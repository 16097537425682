import { MdOutlineKeyboardArrowRight, MdDownload } from "react-icons/md";
import { Link } from "react-router-dom";

function NarrowInfoView(props) {
    return (
        <div className="bg-white rounded-xl m-auto flex flex-col h-full">
            <div className="w-full h-min p-4 text-blue-dark flex flex-col justify-between">
                <div>
                    <div className="text-xl text-golden font-bold mb-4">
                        {props.title}
                    </div>
                    {props.content}

                </div>
                {props.button && !props.button.external && <div className="w-full">
                    <Link to={props.button.link}>
                        <div className="whitespace-pre-line mt-4 p-4 text-white bg-golden font-bold cursor-pointer rounded-2xl shadow-md flex-nowrap flex flex-row items-center space-x-2">
                            <div>
                                {props.button.text}
                            </div>
                            <div>
                                <MdOutlineKeyboardArrowRight size={30} />
                            </div>
                        </div>
                    </Link>
                </div>}

                {props.button && props.button.external &&
                    <a href={props.button.link} alt="download" target="_blank" rel="noreferrer">
                        <div className="whitespace-pre-line mt-4 p-4 text-white bg-golden font-bold cursor-pointer rounded-2xl shadow-md flex-nowrap flex flex-row items-center space-x-2">
                            <div>
                                <MdDownload size={30} />
                            </div>
                            <div>
                                {props.button.text}
                            </div>
                        </div>
                    </a>
                }
                {props.link && <div className="mt-4">
                    <a href={props.link} target="_blank" rel="noreferrer">{props.link}</a>
                </div>}
            </div>

        </div>
    );
}

export default NarrowInfoView;
