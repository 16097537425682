import { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";


function Navbar(props) {

    const [expanded, setExpanded] = useState(false)

    const loc = useLocation();
    return (
        <div className="absolute w-full z-30">
            <div className="hidden md:block absolute w-full top-0 h-48 z-40 bg-gradient-to-b from-blue-dark to-transparent">
            </div>
            <div className="hidden md:block">
                <ul className="absolute top-0 w-full flex flex-row justify-center text-white p-4 z-50">
                    {props.menu.map((item, index) => {
                        if (!item.hiddenInMenu) {

                            return (
                                <Link to={item.link} key={index} className="">
                                    <li className={`p-2 ${loc.pathname === item.link ? "font-bold" : ""} ${index < props.menu.length - 1 ? "textShadow" : "p-2 rounded-full bg-blue-light"}`}>
                                        {item.name}
                                    </li>
                                </Link>
                            )
                        }
                        return (
                            <div key={index}></div>
                        )
                    })}
                </ul>
            </div>
            <div className="text-white absolute top-0 right-0 md:hidden z-50 p-4 text-right flex flex-row justify-end" onClick={() => { setExpanded(!expanded) }}>
                <GiHamburgerMenu size="2em" />
            </div>
            <div className={`${expanded ? "" : "hidden"} absolute top-0 w-full md:hidden bg-blue-dark p-4 z-40`}>
                <ul className="flex flex-col justify-between text-white items-center">
                    {props.menu.map((item, index) => {
                        if (!item.hiddenInMenu) {
                            return (
                                <Link to={item.link} key={index} className="" onClick={() => { setExpanded(false); }}>
                                    <li className={`p-2 ${loc.pathname === item.link ? "font-bold" : ""} ${index < props.menu.length - 1 ? "" : "p-2 rounded-full bg-blue-light"}`}>
                                        {item.name}
                                    </li>
                                </Link>
                            )
                        }
                        return (
                            <div key={index}></div>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
