import { MdOutlineKeyboardArrowRight, MdDownload } from "react-icons/md";
import { Link } from "react-router-dom";

function WidePhotoAndInfoView(props) {
    return (
        <div className="bg-white rounded-2xl m-auto flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/3">
                {props.image && <img src={props.image} alt="Event" className={`object-cover w-full h-full rounded-tl-2xl lg:rounded-bl-2xl rounded-tr-2xl lg:rounded-tr-none ${props.imgPadding ? "p-4" : ""}`} />}
                {props.video && <div className="w-full h-full py-8 pl-8 pr-8 lg:pr-0">
                    <iframe width="100%" height="100%" src={props.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>}

            </div>
            <div className="w-full lg:w-2/3 p-8 text-blue-dark flex flex-col justify-between">
                <div>
                    <div className="text-3xl text-golden font-bold mb-4">
                        {props.title}
                    </div>
                    {props.content}

                </div>
                {props.button && !props.button.external &&
                    <Link to={props.button.link}>
                        <div className="w-full md:w-min whitespace-pre-line md:whitespace-nowrap mt-4 p-4 text-golden font-bold cursor-pointer rounded-2xl shadow-lg flex flex-row items-center space-x-2">
                            <div>
                                {props.button.text}
                            </div>
                            <div>
                                <MdOutlineKeyboardArrowRight size={30} />
                            </div>

                        </div>
                    </Link>
                }
                {props.button && props.button.external &&
                    <a href={props.button.link} alt="download" target="_blank" rel="noreferrer" className="w-min">
                        <div className="w-full md:w-min whitespace-pre-line md:whitespace-nowrap mt-4 p-4 text-golden font-bold cursor-pointer rounded-2xl shadow-lg flex flex-row items-center space-x-2">
                            <div>
                                <MdDownload size={30} />
                            </div>
                            <div>
                                {props.button.text}
                            </div>
                        </div>
                    </a>
                }
            </div>

        </div>
    );
}

export default WidePhotoAndInfoView;
