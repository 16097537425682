import duck from "../assets/duck.png";
import Footer from "./Footer";
import settings from "../assets/settings.json";

function BgHolder(props) {
    return (
        <div className="w-full">
            <div className="h-screen/2 relative">
                <img src={props.mainImage} alt="Background" className="h-full w-full bg-auto absolute object-cover object-bottom" />
                <div className="absolute bottom-0 lg:-bottom-16 w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="" style={{ height: "50%" }}>
                        <path fill="#1d378a" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,176C640,181,800,203,960,186.7C1120,171,1280,117,1360,90.7L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                    </svg>
                </div>
                <div className="absolute bottom-0 lg:-bottom-16 w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="">
                        <path fill="#18245c" fillOpacity="1" d="M0,160L80,154.7C160,149,320,139,480,160C640,181,800,235,960,245.3C1120,256,1280,224,1360,208L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                    </svg>
                </div>
                <div className="absolute w-full lg:-bottom-24 z-10">
                    <div className="w-full md:w-4/5 m-auto flex flex-col md:flex-row mt-16 justify-center md:justify-start">
                        <img src={duck} alt="Duck" className="mx-24 md:m-0 w-50 md:w-72 self-center" />
                        <div className="invisible lg:visible self-center ml-4 text-white text-4xl mt-8 md:mt-16 text-center md:text-left textShadow">
                            <div>
                                {settings.entencupNumber}.
                            </div>
                            <div>
                                DRESDNER
                            </div>
                            <div>
                                ENTENCUP
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="w-full pt-1 lg:pt-24 bg-blue-dark">
                <div className="relative w-4/5 m-auto pb-12 lg:pb-48 z-50">
                    {props.s1}
                </div>
                <div className="bg-blue-dark relative">

                    <div className="absolute -bottom-8 w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#1d378a" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,176C640,181,800,203,960,186.7C1120,171,1280,117,1360,90.7L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                        </svg>
                    </div>
                    <div className="absolute -bottom-8 w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#36a7dc" fillOpacity="1" d="M0,160L80,154.7C160,149,320,139,480,160C640,181,800,235,960,245.3C1120,256,1280,224,1360,208L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                        </svg>
                    </div>

                </div>
            </section>
            <section className="">
                <div className="bg-blue-light z-20">

                    <div className="relative w-4/5 m-auto lg:pb-24 pt-16 lg:pt-8 z-50">
                        {props.s2}
                    </div>
                </div>
            </section>
            <section className="z-30">
                <div className="bg-blue-medium relative">

                    <div className="absolute bottom-0 w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#18245c" fillOpacity="1" d="M0,160L80,154.7C160,149,320,139,480,160C640,181,800,235,960,245.3C1120,256,1280,224,1360,208L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                        </svg>
                    </div>
                    <div className="w-4/5 m-auto transform lg:pb-32 -translate-y-32 z-50">
                        {props.s3}
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-blue-dark">
                    <Footer />
                </div>
            </section>


        </div>
    );
}

export default BgHolder;
