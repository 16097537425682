import BgHolder from "../components/BgHolder";
import NarrowInfoView from "../components/NarrowInfoView";
import TwoSidedView from "../components/TwoSidedView";
import headerImage from "../assets/header/entencup-2016-03-edit.jpg";
import musaik from "../assets/spendenziel/musaik.jpeg"
import gallery from "../assets/gallery.json" // here should be names of image files.
import { useEffect } from "react";
import UtilAnalytics from "../components/Analytics";


function Datenschutz() {
    useEffect( () => {
        UtilAnalytics.page("/datenschutz");
    }, [])

    return (
        <div className="">
            <BgHolder
                mainImage={headerImage}
                // "https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg"
                s1={
                    <div className="z-50 mb-24">
                        <div className="my-12"></div>
                        <NarrowInfoView
                            title={"Datenschutzerklärung"}
                            content={
                                <div>
                                    <div>
                                        <b class="mb-2">Geltungsbereich</b> 
                                        <p class="mb-2">
                                        Diese Datenschutzerklärung klärt Nutzer über die Art, den Umfang und die Zwecke der Erhebung und Verwendung pesonengebundener Daten durch den verantwortlichen Anbieter auf dieser Website (im folgenden "Angebot") auf.
                                        </p>
                                        <p>
                                        Die rechtlichen Grundlagen des Datenschutzes finden sich im Bundesdatenschutzgesetz (BDSG) und dem Telemediengesetz (TMG).
                                        </p>
                                    </div>
                                    <div class="mb-6"></div>
                                    <div>
                                        <b class="mb-2">Verwendung von Facebook Social Plugins</b> 
                                        <p class="mb-2">
                                        Dieses Angebot verwendet Social Plugins ("Plugins") des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"). Die Plugins sind an einem der Facebook Logos erkennbar (weißes „f“ auf blauer Kachel, den Begriffen "Like", "Gefällt mir" oder einem „Daumen hoch“-Zeichen) oder sind mit dem Zusatz "Facebook Social Plugin" gekennzeichnet. Die Liste und das Aussehen der Facebook Social Plugins kann hier eingesehen werden: https://developers.facebook.com/docs/plugins/.
                                        </p>
                                        <p class="mb-2">
                                        Wenn ein Nutzer eine Webseite dieses Angebots aufruft, die ein solches Plugin enthält, baut sein Browser eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden. Der Anbieter hat daher keinen Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert die Nutzer daher entsprechend seinem Kenntnisstand:
                                        </p>
                                        <p class="mb-2">
                                        Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die entsprechende Seite des Angebots aufgerufen hat.Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button betätigen oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Browser direkt an Facebook übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine anonymisierte IP-Adresse gespeichert.
                                        </p>
                                        <p class="mb-2">
                                        Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer , können diese den Datenschutzhinweisen von Facebook entnehmen: https://www.facebook.com/about/privacy/.
                                        </p>
                                        <p class="mb-2">
                                        Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über dieses Angebot Daten über ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor dem Besuch des Internetauftritts bei Facebook ausloggen. Weitere Einstellungen und Widersprüche zur Nutzung von Daten für Werbezwecke, sind innerhalb der Facebook-Profileinstellungen möglich: https://www.facebook.com/settings?tab=ads.
                                        </p>
                                    </div>
                                    <div class="mb-6"></div>
                                    <div>
                                        <b class="mb-2">Widerruf, Änderungen, Berichtigungen und Aktualisierungen</b>
                                        <p>
                                        Der Nutzer hat das Recht, auf Antrag unentgeltlich Auskunft zu erhalten über die personenbezogenen Daten, die über ihn gespeichert wurden. Zusätzlich hat der Nutzer das Recht auf Berichtigung unrichtiger Daten, Sperrung und Löschung seiner personenbezogenen Daten, soweit dem keine gesetzliche Aufbewahrungspflicht entgegensteht.
                                        </p>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                }
                s2={
                    <div className="mb-24">
                    </div>
                }
                s3={
                    <div>
                    </div>
                }

            >


            </BgHolder>
        </div>
    );
}

export default Datenschutz;
